.bg-primary {
    background-color: #242056!important;
}
.btn-primary {   
    background-color: #242056;
    border-color: #242056;
}
.btn-primary:hover {
    background-color: #150e72;
    border-color: #150e72;
}
.btn-warning {
    background-color: #EF5713;
    border-color: #EF5713;
}
.btn-warning:hover{
    background-color: #ff6a27;
    border-color: #ff6a27;
}
.btn-success {
    background-color: #21bd59;
    border-color: #21bd59;
}
.pagination  .page-link {color: #242056;}
.pagination .page-item.active .page-link {
    background-color:#EF5713;
    border-color:#EF5713;
}
.card-login .card-body{padding: 40px;}
.title{position: relative;margin-bottom: 20px;}
.title h4{font-weight: 600;color: #000;}
.sidebar__inner{  
    position: sticky;
    top: 0px;
    z-index: 100;
    height: 100%;
    left: 0px;
    }
    .sidebar {
        width: 16rem!important;
    }
.sidebar-dark .sidebar-brand {
    background-color: #fff;
}
.sidebar-dark .sidebar-brand img{width: 100px;}
.sidebar .nav-item .collapse .collapse-inner .collapse-item{font-size: 12px;
    font-weight: 400;
    color: #fff;}
    .sidebar .nav-item .collapse .collapse-inner .collapse-item:hover, .sidebar .nav-item .collapsing .collapse-inner .collapse-item:hover {
        background-color:#1d1a4c;
    }
.topbar .nav-item .nav-link .img-profile {
    object-fit: cover;
}
.topbar.navbar-light .navbar-nav .nav-item .nav-link span{margin-left:10px;color: #000;}
.main-title{position: absolute;
    top: 15px;}
.main-title h3{color: #000;font-weight:700;font-size: 20px;}
.breadcrumb {
    padding: 0px 0px 10px;
    background-color: transparent;
    border-radius: 0px;
}
.breadcrumb .breadcrumb-item{    font-size: 13px;
    font-weight: 500;}
.card {margin-bottom: 20px;
    border: none;
    box-shadow: 0px 0px 14px 5px rgb(8 8 8 / 3%);
}
.card-header {background-color: #fff;color: #222;}
.dashboard-card h6{font-size: 16px;}
.dashboard-card h4{    font-size: 24px;
    font-weight: 700;
    color: #242056;}
    .titleuser h5{font-weight: 500;color: #000;font-size: 14px;}
    .doceg{position: relative;list-style: decimal;padding-left: 12px;}
    .doceg>li{font-size: 11px;color: #999;line-height: 18px;margin-bottom: 10px;}