/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  /*box-shadow: inset 0 0 5px #e0eeff;*/
  background: #f0f0f0;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #616161;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4a4949;
}
.checkboxMt{margin-top: 20px;display: flex;    align-items: center;}
.checkboxMt label.checkboxtitle{margin-bottom: 0px;
  margin-right: 10px;}
.checkboxMt>input[type=radio], .checkboxMt>input[type=checkbox]{width:24px;}
.background-auth-modal{
    background-image: url('../images/big/auth-bg.jpg');
}
.auth-modal{
    background-image: url('../images/big/3.jpg');
}

.switch {
    position: relative;
    display: inline-block;
    width: 90px;
    height: 34px;
  }
  
  .switch input {display:none;}
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ca2222;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2ab934;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(55px);
    -ms-transform: translateX(55px);
    transform: translateX(55px);
  }
  
  /*------ ADDED CSS ---------*/
  .on
  {
    display: none;
  }
  
  .on, .off
  {
    color: white;
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    font-size: 10px;
    font-family: Verdana, sans-serif;
  }
  
  input:checked+ .slider .on
  {display: block;}
  
  input:checked + .slider .off
  {display: none;}
  
  /*--------- END --------*/
  

  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  .mt-2em{
     margin-top:2em;
  }
  .img-logo{
    height:100px;
    width:100px;
    border-radius:50px;
  }
  .img-banner{
    height:100px;
    width:100px;
  }
  .width368{
    width: 368px;
  }
  /* image checkbox */
  .nopad {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  /*image gallery*/
  .image-checkbox {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 4px solid transparent;
    margin-bottom: 0;
    outline: 0;
  }
  .image-checkbox input[type="checkbox"] {
    display: none;
  }
  
  .image-checkbox-checked {
    border-color: #4783B0;
  }
  .image-checkbox .fa {
    position: absolute;
    color: #4A79A3;
    background-color: #fff;
    padding: 10px;
    top: 0;
    right: 0;
  }
  .image-checkbox-checked .fa {
    display: block !important;
  }

  .img-fluid{
    max-width: 52% !important;
  }
  .nopad{
    border-style: dotted;
  }

  .image-gallery-fluid{
    max-width: 100% !important;
  }

  .image-gallery-height{
    height: 77% !important;
  }

  .action-icons{
    position: absolute;
    display: flex;
    flex-direction: row;
    width:102%;
    justify-content: end;
    z-index:+9
  }
  .action-icons > i{
    padding: 3px;
    color: #043363;
    background-color: rgba(0,0,0,0.3);
  }
  .custom-round-icon{
    border-radius: 100%;
    width: 25px;
    height: 25px;
    line-height: 20px;
    padding: 0px;
  }
  .empty-state-txt{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .mt20{
    margin-top:20px;
  }
  .mt25{
    margin-top:25px;
  }
  .mt-40{
    margin-top:40px;
  }
  .mb-40{
    margin-top:40px;
  }
  .alignItemsCenter{
    align-items:center
  }
  .padding-86{
    padding: 86px;
  }
  .td-Width-200{
    width:234px;
  }
  .p-width-135{
    width:130px;
  }
  .custom-card-deck{
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.custom-card{
  display: flex;
  flex: 1 0;
  flex-direction: row;
  margin-right: 15px;
  margin-bottom: 0;
  margin-left: 15px;
  justify-content: center;
  padding: 9px;
  border-width: thick;
  border-color:#a56b49;
  border-style: dotted;
}

.wrapper-editor{
  color:blue;
}
.content-center{
  flex-direction: column;
align-items: center;
justify-content: center;
}

.active-card-border{
border-color: green;
border-style: solid;
border-width: medium;
}

.inactive-card-border{
  border-color:red;
  border-style: solid;
  border-width: medium;
  }
.w-10{
  width:10% !important;
}
.w-15{
  width:15% !important;
}
.w-20{
  width:20% !important;
}
.w-35{
  width:35% !important;
}
.w-100{
  width:100% !important;
}
.icon-container{
  display: flex;
flex-direction: row;
align-items: center;
}
.base-color{
  color:#043363;
  font-weight: bolder;
}
.team-action-buttons{
  padding:10px;
}
.propertyList {border-bottom: solid 2px #ccc; padding-bottom: 30px; margin-bottom: 30px;}
.propertyList>a:hover{text-decoration: none;}
.propertyList:last-child{ border-bottom: none; padding-bottom: 0; margin-bottom: 0; }
.propertyList img{ border-radius: 50%; height: 100px; width: 100px; object-fit: cover; }
.orangetext{ color: #EF5713; margin-bottom: 10px;}
.orangetext span{ background:#EF5713; height: 8px; width: 8px; border-radius: 50%; display: inline-block;
  margin-right: 10px;}
  .propertyList .h6{ color: #000; font-size: 22px; font-weight: 700; margin-bottom: 5px;}
  .openingbidtext{
    font-size: 18px;
    color: #666;
    font-weight: 600;
    margin-bottom: 5px;
  }
  .pricetext{
    color: #000;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 5px;
  }
  .pdfBox{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
  }
  .pdfBox img{ width: 70px;}
  .pdfBox span{ font-size: 16px; color: #000; margin-top: 10px; font-weight: 600; display: inline-block; }
  .pdfBox span:hover{ color: #EF5713; }
  
  .documentStatus{
    display: flex;
    width: 100%;
    height: 250px;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px #ccc;
    padding-bottom: 0;
    margin-bottom: 15px;
  }
  .documentlist-img .documentStatus {align-items: self-start;}
  .documentlist-img .documentStatus h5{color: #000;font-size: 16px;margin-bottom: 5px;}
  .documentlist-img .documentStatus p{color: #777;font-size: 13px;}
  .documentStatus .avatar-imgSize{width: 100%;
    height: 165px;
    object-fit: contain;}
  .documentStatus .profile-imgSize{width: 100px;height: 100px;
    object-fit: contain;}
  .statusbtns{
    display: flex;
    margin-right: 20px;
    justify-content: space-between;
  }
  .statusbtns button i{ 
    color: #fff;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    line-height: 35px;
  }
  .statusbtns button{
    background: transparent;
    border: none;
    padding: 0;
    margin-right: 20px;
  }
  .statusbtns button:focus{ outline: none; box-shadow: none; }
  .statusbtns button.approve i{background: #32ae0c;}
  .statusbtns button.reject i{background: #ff0101;}
  .results-list {
    list-style-type: none;
    padding: 0;
    margin: 10px 0;
  }
  
  .results-list li {
    background-color: #f4f4f4;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 5px;
  }
  
  .results-list li:hover {
    background-color: #e0e0e0;
    cursor: pointer;
  }
  .bidderlist{
    border: 1px solid lightgray;
    padding-left: 25%;
    padding-right: 25%;
    padding-bottom: 2%;
    cursor: pointer;
  }
  .statusbtns button i:hover{ opacity: .6;}
  /*=========== custom ============*/
  .nav-tabs {margin-bottom: 20px;    border-bottom: none;}
  .nav-tabs .nav-link {
    font-size: 13px;border: none;
    font-weight: 500;
    color: #636363;}
    .nav-pills .nav-link.active, .nav-pills .show>.nav-link { background-color: #242056;}
    .propertyList p{font-size: 13px;font-weight: 400;}
    .propertyList h4{    color: #000;
      font-size: 18px;
      font-weight: 600;}
    .propertyList h5{font-size: 15px;font-weight: 500;color: #000;}
    .propertyList h5.orangetext{color: #EF5713;}
    .propertyList .time{font-size: 12px;
      font-weight: 500;}
      .propertyList h6{font-size: 13px;
        font-weight: 500;}
        .propertyDetails h5{font-size: 14px;}
        .bid-hand{}
        .bid-hand>img{width: 80px;height: 80px;object-fit: contain;transition: 0.5s;}
        .bid-hand:hover>img{    transform: scale(1.1);    -o-transform: scale(1.1);    -webkit-transform: scale(1.1);}
        .dec-text{position: relative;margin-bottom: 20px;}
        .dec-text p{color: #333; margin: 0.5rem;}
        .border{border: 1px solid;}
        .list-images{position: relative;margin-left: -15px;
          margin-right: -15px;}
        .list-images-inline{width: 20%;padding: 0px 15px;margin-bottom: 20px;}
        .list-images-inline img{width: 100%;
          height: 125px;
          object-fit: contain;
          background-color: #f9f9f9;
          border: 1px solid #f0f0f0;
          margin-bottom: 14px;
          border-radius: 4px;}
          .list-images-inline h6{color: #000;}
          .list-images-inline p{color: #333;}
          .stats {
            display: flex;
            width: 100%;
            justify-content: space-between;
            margin: 20px 0px;
        }
        
        .stat-card {
            background-color: #e0f2f7   ;
            padding: 10px;
            text-align: center;
            flex: 1;
            margin: 0 30px;
            border-radius: 8px;
        }
        
        .stat-card h2 {
            margin: 0;
            font-size: 28px;
            color: #5a5cce;
        }
        
        .stat-card p {
            margin: 10px 0 0;
            font-size: 16px;
            color: #5a5cce;
        }
        .controls {
          display: flex;
          align-items: center;
      }
      
      .controls input[type="text"] {
          padding: 10px;
          border: 1px solid #D0D5DD;
          border-radius: 4px;
          margin-right: 10px;
      }
      
      .controls .btn {
          padding: 6px 15px;
          border: none;
          background-color: #ffffff;
          color: rgb(0, 0, 0);
          border-radius: 4px;
          margin-right: 10px;
          border: 1px solid #cccccc;
          cursor: pointer;
      }
      
      .controls .btn.download {
          background-color: #ffffff;
          margin-left: auto;
      }
      /* .controls .btn.download :hover {
        background-color: #043363;
        margin-left: auto;
    } */
    
      
      .controls .btn:hover {
          opacity: 0.9;
          background-color: #242056;
          color: #fff;
      }

      .main-tabs {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        border-bottom: 0px solid white;
        margin: 20px 0px;
    }
    
    .main-tab {
        width: 150px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-size: 14px;
        color: black;
        cursor: pointer;
        background-color: white;
        transition: background-color 0.3s, color 0.3s;
    }
    
    .main-tab:last-child {
        margin-right: 0;
    }
    
    .main-tab.active {
        border-bottom: 2px solid blue;
        background-color: white;
        color: blue;
    }
    
    .main-tab:hover{
        background-color: rgb(222, 220, 218);
    }  
    
    
.containers12 {
  width: 98%;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  margin-left: 10px;
  margin-top: 10px;
}

.containers122 {
  width: 98%;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  margin-left: 10px;
}
.header12 {
  display: flex;
  justify-content:flex-start;
  align-items: center;
  margin-bottom: 20px;
}
.header112 {
  display: flex;
  justify-content:flex-start;
  align-items: center;
}
.header12 h2 {
  margin: 0;
  margin-right: 10px;
}

.header12 label {
  margin: 0;
}
.section-tabs {
  display: flex;
  justify-content:left;
  margin-bottom: 0px;
}
.set {
  display: flex;
  justify-content: space-around;
}

.section-tab {
  display: flex;
  justify-content: center;
  padding: 6px 20px;
  cursor: pointer;
  font-size: 14px;
  color: black;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 0 5px;
  background-color: #F3F4F6;
  transition: background-color 0.3s, color 0.3s;
}

.section-tab.active {
  color: #f60;
  border: 1px solid #EE5819 !important;
  background-color: rgb(242, 219, 198);
}

.section-tab:hover {
  background-color: #242056;
  color: #fff;
}

.right1 {
  display: flex;
  justify-content: right;
  margin-left: auto;
}

.main-image img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}
.small-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.small-image img {
  max-width: 100%; 
  max-height: 100%;
}

.Suggested-image {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.Suggested-image button {
  background: none;
  border: none;
   cursor: pointer;
}

.Suggested-image .thumbnails {
  display: flex;
  overflow-x:visible;
  width: 100%;
  margin: 0 10px;
}

.Suggested-image .thumbnails img {
  margin-right: 10px;
  cursor: pointer;
}


.Suggested-image .thumbnails img:last-child {
  margin-right:auto;
}

.main-image2 {
   display: flex;

}

.main-img {
  display: block; /* Removes any gap below the image */
  width: 100%;
  /* Adjust as needed */
}

.small-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px; /* Adjust size as needed */
}
.rejected-image img{
margin-top: 10px;
}
.rejectedimage{
  display: flex;
  margin-top: 10px;
}

.listclass :hover {
  background-color: #043363;
  cursor: pointer;
}

.hrclass {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}