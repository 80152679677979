.img-preview {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.5rem;
}

.img-preview__item {
    position: relative;
    width: 100px;
    height: 100px;
    margin: 0.5rem;
}

.img-preview__access {
    position: relative;
    margin: 0.5rem;
}
.img-preview__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.img-preview__remove {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5rem 0.75rem;
    background: #000;
    color: #fff;
    font-size: 0.75rem;
    line-height: 1;
    cursor: pointer;
}

.img-preview__remove:hover {
    background: #f00;
}

.preview__video {
    width: 300px;
    height: 200px;
}

.preview__video_media {
    width: 100px;
    height: 100px;
    margin-left: 10px;
    cursor: pointer;
}
.preview__video_media1 {
  width: 100px;
  height: 100px;
  margin-left: 10px;
  cursor: pointer;
  object-fit: cover;
}
.selectedVideo{
    width: 50%;
    height: 350px;
}
.selectedImage{
   height: 350px;
}
/* Chrome, Safari, Edge, Opera */
.hide-number-input-arrow input::-webkit-outer-spin-button,
.hide-number-input-arrow input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.hide-number-input-arrow input[type=number] {
    -moz-appearance: textfield;
}

/* Chrome, Safari, Edge, Opera */
 input::-webkit-outer-spin-button,
 input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
 input[type=number] {
    -moz-appearance: textfield;
}
.new-tooltip span {
    visibility: hidden;
    width: 320px;
    background-color: white;
    color: black;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    left: 32%;
    margin-left: 199px;
    cursor: pointer;
    border: 1px solid;
  }

  .new-tooltip:hover span {
    visibility: visible;
  }
  .new-tooltip1 span {
    visibility: hidden;
    width: 270px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    left: 0%;
    top: 100%;
    z-index: 1;
    cursor: pointer;
  }

  .new-tooltip1:hover span {
    visibility: visible;
  }
  .sortingImage{
    display: inline-block;
    vertical-align: middle;
   
  }
  .sortingImage img{
    display: block;
    max-width: 100px;
    max-height: 100px;
    width: 14px;
    margin-bottom: 3px;
    cursor: pointer;
  }
    .wide-heading {
        width: 300px;
    }
    /* ImageScroller.css */
.scroll-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
  }
  
  .image-container {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    white-space: nowrap;
    flex-grow: 1;
    margin: 0; /* Space for the arrows */
    scrollbar-width: none; 
  }
  
  .image-container::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }
  .image-container img {
    max-width: 100px; /* Adjust based on your needs */
    height: 100px;
  }
  
  .scroll-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 10;
  }
  
  
  .left-arrow {
    left: -10px;
    border-radius: 50%;
  }
  
  .right-arrow {
    right: -15px;
    border-radius: 50%;
  }
  .image-item input[type="checkbox"] {
    position: relative;
    left: 100px;
    top: 12px;
    }
    .image-item1 input[type="checkbox"] {
      position: relative;
      left: 100px;
      z-index: 1;
      }
  .mediaTable  tr:nth-child(even) {
    background-color: #F5F5F6;
  }
  .overlay_img {
    position: absolute;
    top: 10px;
    left: 22px;
    width: 100px;
    height: 100px;
    background-color: rgba(255, 0, 0, 0.1); /* Red color with 50% transparency */
    z-index: 1;
}
.foreground-image {
    position: relative;
    top: 20%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 45px; /* Set the desired width for the foreground image */
    height: 45px !important;
    margin-left: -55px;
}
.foreground-image1 {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 45px; /* Set the desired width for the foreground image */
  height: 45px !important;
  margin-left: -55px;
}
.image-container_in{
    display: inline-block; 
}
.overlay_img_ap {
  position: absolute;
  top: 10px;
  margin-left: 10px;
  width: 100px;
  height: 100px;
  background-color: rgba(30, 86, 255, 0.3);/* Red color with 50% transparency */
  z-index: 1;
}
.video_banner {
  position: absolute;
  left: 188px;
  margin-top: 30px;
  width: 3%;
}
.approve-image{
  margin-top: 12px;
}